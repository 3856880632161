import jwt_decode from 'jwt-decode';
import { createBrowserHistory } from 'history';
import { toast } from 'react-toastify';
import { utils } from '@ohif/core';
import store from '../store';

const history = createBrowserHistory();
// const api_url = 'http://10.0.8.64:8000/api/';
// const api_url = 'http://10.0.8.15:8000/api/';
// const api_url = 'http://10.0.10.99:8000/api/';
// const api_url = 'http://192.168.18.132:8000/api/';
// const api_url = 'http://192.168.18.17:9000/api/';
//const api_url = 'http://localhost:8000/api/';
//const api_url = 'http://192.168.18.61:8000/api/';
//const api_url = 'http://192.168.18.222:8000/api/';
//const api_url = 'http://172.16.11.119:8000/api/';
// const api_url = 'http://3.23.239.239/api/';
//const api_url = 'http://3.134.36.59/api/'; //new xycad
const api_url = 'https://chibackend.xylexa.ai/api/'; // Test

export const userService = {
  isauth,
  logout,
  login,
  server_config,
  handleResponse,
  authHeader,
  handleAlert,
  api_url,
  refreshToken,
  getUserId,
  getData,
};

function isauth() {
  try {
    if (!localStorage.getItem('user') || !localStorage.getItem('auth')) {
      logout();
    }
  } catch (err) {
    logout();
  }
}

function logout(props_history) {
  localStorage.clear();
  store.dispatch({
    type: 'USER_LOGOUT',
  });
  if (props_history) {
    props_history.push('/login');
  } else {
    history.push('/login');
  }
}

var login = async (username, password) => {
  let body = {
    username: username,
    password: password,
  };
  // server_config();

  // console.log('login');
  await getData('token/', 'post', body)
    .then(async data => {
      localStorage.setItem('auth', JSON.stringify(data));
      const auth = JSON.parse(localStorage.getItem('auth'));
      const decoded = jwt_decode(auth.access);
      await getData('v1/users/' + decoded.user_id + '/', 'get')
        .then(async data => {
          localStorage.setItem('user', JSON.stringify(data));

          return true;
        })
        .catch(error => {
          return false;
        });
    })
    .catch(error => {
      return false;
    });
};

function server_config(reload = false, pacs_type = 'local') {
  // isauth();
  JSON.parse(localStorage.getItem('user'))
    ? console.log('')
    : login('chi', 'chi@1234');
  let servers = {};

  let localServer = [
    {
      PACS_type: 'local',
      name: 'Orthanc',
      wadoUriRoot: 'https://xray-pacs.eclinicpk.com/wado',
      qidoRoot: 'https://xray-pacs.eclinicpk.com/dicom-web',
      wadoRoot: 'https://xray-pacs.eclinicpk.com/dicom-web',
      requestOptions: {
        auth: 'T1iH2bsR:th3C0AvI',
        requestFromBrowser: true,
      },
      qidoSupportsIncludeField: true,
      imageRendering: 'wadors',
      thumbnailRendering: 'wadors',
    },
  ];

  // console.log('Static server info-102', localServer);

  // for (let i = 0; i < user.groups.length; i++) {
  //   var group = user.groups[i].split('/');
  //   group_ids.push(group[group.length - 2]);
  // }

  if (pacs_type == 'cloud') {
    // const user = JSON.parse(localStorage.getItem('user'));
    // setTimeout(() => console.log('waiting'), 5000);

    // console.log('user', JSON.parse(localStorage.getItem('user')));
    let group = JSON.parse(localStorage.getItem('user')).groups[0].split('/');
    let group_id = group[group.length - 2];
    // isauth();
    getData(
      'v1/groupprofiles/?group_id=' + group_id + '&pacs_type=cloud',
      'get'
    )
      .then(async data => {
        servers[0] = data['results'];
        await get_server_statistics(servers).then(async servers => {
          await utils.addServers(servers, store);
        });

        handleAlert('success', 'You are connected to Cloud DICOM Server!');
      })
      .then(() => {
        if (servers) {
          if (reload) {
            store.dispatch({
              type: 'USER_LOGOUT',
            });
          }
          utils.addServers(servers, store);
        } else {
          handleAlert('error', 'The servers and store must be defined');
        }
      })
      .catch(error => {
        handleAlert('error', 'The servers and store must be defined');
      });
  } else {
    let a = document.createElement('a');
    a.href = localServer[0]['wadoRoot'];
    // console.log('wado local', a);

    // let base_url = a.host;
    // let protocol = a.protocol;
    // const requestOptions = {
    //   method: 'GET',
    //   headers: {
    //     'Content-Type': 'text/plain',
    //     Authorization: 'Basic ' + btoa(localServer[0].requestOptions.auth),
    //   },
    // };

    // fetch(protocol + '//' + base_url + '/tools/now', requestOptions)
    //   .then(response => response.text())
    //   .then(result => {
    //     servers[0] = localServer;
    //     get_server_statistics(servers).then(servers => {
    //       utils.addServers(servers, store);
    //     });
    //     handleAlert('success', 'You are connected to Local DICOM Server!');
    //   });
    servers[0] = localServer;
    get_server_statistics(servers)
      .then(servers => {
        utils.addServers(servers, store);
        handleAlert('success', 'You are connected to Local DICOM Server!');
      })
      .then(() => {
        if (servers) {
          if (reload) {
            store.dispatch({
              type: 'USER_LOGOUT',
            });
          }
          utils.addServers(servers, store);
        } else {
          handleAlert('error', 'The servers and store must be defined');
        }
      })
      .catch(error => {
        handleAlert('error', 'The servers and store must be defined');
      });
  }

  // ------------------------------------------------

  // getData(
  //   'v1/groupprofiles/?group_id=' + group_id + '&pacs_type=' + pacs_type,
  //   'get'
  // )
  //   .then(data => {
  //     console.log('first call for server-135-isAuth', data);
  //     let a = document.createElement('a');
  //     a.href = data['results'][0]['wadoRoot'];
  //     let base_url = a.host;
  //     let protocol = a.protocol;
  //     const requestOptions = {
  //       method: 'GET',
  //       headers: {
  //         'Content-Type': 'text/plain',
  //         Authorization:
  //           'Basic ' + btoa(data['results'][0].requestOptions.auth),
  //       },
  //     };
  //     if (pacs_type == 'cloud') {
  //       getData(
  //         'v1/groupprofiles/?group_id=' + group_id + '&pacs_type=cloud',
  //         'get'
  //       ).then(data => {
  //         servers[0] = data['results'];
  //         get_server_statistics(servers).then(servers => {
  //           utils.addServers(servers, store);
  //         });

  //         handleAlert('success', 'You are connected to Cloud DICOM Server!');
  //       });
  //     } else {
  //       fetch(protocol + '//' + base_url + '/tools/now', requestOptions)
  //         .then(response => response.text())
  //         .then(result => {
  //           servers[0] = data['results'];
  //           console.log('local server', data['results']);
  //           get_server_statistics(servers).then(servers => {
  //             utils.addServers(servers, store);
  //           });
  //           // handleAlert('success', 'You are connected to Local DICOM Server!');
  //         })
  //         .catch(error => {
  //           handleAlert('error', 'Local DICOM Server is not available!');
  //           getData(
  //             'v1/groupprofiles/?group_id=' + group_id + '&pacs_type=cloud',
  //             'get'
  //           ).then(data => {
  //             servers[0] = data['results'];
  //             get_server_statistics(servers).then(servers => {
  //               utils.addServers(servers, store);
  //             });
  //             handleAlert(
  //               'success',
  //               'You are connected to Cloud DICOM Server!'
  //             );
  //           });
  //         });
  //     }
  //     // data.results.map((item, key) => (servers[key] = [item]));
  //   })
  //   .then(() => {
  //     if (servers) {
  //       if (reload) {
  //         store.dispatch({
  //           type: 'USER_LOGOUT',
  //         });
  //       }
  //       utils.addServers(servers, store);
  //     } else {
  //       handleAlert('error', 'The servers and store must be defined');
  //     }
  //   })
  //   .catch(error => {
  //     handleAlert('error', 'The servers and store must be defined');
  //   });
}

const get_server_statistics = servers => {
  return new Promise((resolve, reject) => {
    const base_url = new URL(servers[0][0].wadoUriRoot).origin + '/statistics';
    const auth = servers[0][0].requestOptions.auth;

    let request = new Request(base_url);
    let options = {
      method: 'GET',
      mode: 'cors',
      cache: 'default',
      headers: { Authorization: 'Basic ' + btoa(auth) },
    };

    fetch(request, options).then(response => {
      response
        .json()
        .then(data => {
          servers[0][0].totalStudies = data['CountStudies'];
          resolve(servers);
        })
        .catch(error => {
          reject(error);
        });
    });
  });
};

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}

function authHeader() {
  // return authorization header with jwt token
  let auth = JSON.parse(localStorage.getItem('auth'));

  if (auth && auth.access) {
    return {
      Authorization: 'Bearer ' + auth.access,
    };
  } else {
    return {};
  }
}

function refreshToken() {
  return new Promise((resolve, reject) => {
    if (localStorage.getItem('user') && localStorage.getItem('auth')) {
      let auth = JSON.parse(localStorage.getItem('auth'));
      const decoded = jwt_decode(auth.access);
      if (decoded.exp < Date.now() / 1000) {
        const url = api_url + 'token/refresh/';
        const options = {
          method: 'POST',
          mode: 'cors',
          cache: 'default',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            refresh: auth.refresh,
          }),
        };

        var request = new Request(url);
        fetch(request, options)
          .then(handleResponse)
          .then(data => {
            auth.access = data.access;
            localStorage.setItem('auth', JSON.stringify(auth));
            resolve();
          })
          .catch(error => {
            logout();
            reject();
          });
      } else {
        resolve();
      }
    } else {
      resolve();
    }
  });
}

function handleAlert(type, msg) {
  const opt = {
    position: 'top-right',
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  };
  if (type == 'error') {
    toast.error(msg, opt);
  } else if (type == 'success') {
    toast.success(msg, opt);
  }
}

function getUserId() {
  isauth();
  const auth = JSON.parse(localStorage.getItem('auth'));
  const decoded = jwt_decode(auth.access);
  return decoded.user_id;
}

function getData(endpoint, method, body = undefined) {
  return new Promise((resolve, reject) => {
    // refreshToken().then(() => {
    let options = {
      method: method.toUpperCase(),
      mode: 'cors',
      cache: 'default',
      headers: {
        ...authHeader(),
        'Content-Type': 'application/json',
      },
    };
    if (body && method.toUpperCase() === 'POST') {
      options.body = JSON.stringify(body);
    }
    let request = new Request(api_url + endpoint);

    fetch(request, options)
      .then(handleResponse)
      .then(data => {
        resolve(data);
      })
      .catch(error => {
        handleAlert('error', error);
        reject(error);
      });
    // });
  });
}
