import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import ConnectedUserPreferencesForm from '../../connectedComponents/ConnectedUserPreferencesForm';
import { Dropdown, AboutContent, withModal } from '@ohif/ui';
import OHIFLogo from '../OHIFLogo/OHIFLogo.js';
import './Header.css';
import { userService } from '../../utils/isauth';

// Context
import AppContext from './../../context/AppContext';

class Header extends Component {
  static contextType = AppContext;
  static propTypes = {
    home: PropTypes.bool.isRequired,
    location: PropTypes.object.isRequired,
    children: PropTypes.node,
    t: PropTypes.func.isRequired,
    userManager: PropTypes.object,
    user: PropTypes.object,
    modalContext: PropTypes.object,
    history: PropTypes.object.isRequired,
    login: PropTypes.bool,
  };

  static defaultProps = {
    home: true,
    children: OHIFLogo(),
  };

  constructor(props) {
    super(props);
    this.state = { isOpen: false };

    this.loadOptions();
    // this.isauth();
  }
  // isauth() {
  //   if (!localStorage.getItem('user') || !localStorage.getItem('auth')) {
  //     this.props.history.push('/');
  //   }
  //   // else {
  //   //   userService.server_config();
  //   // }
  // }

  loadOptions() {
    const {
      t,
      // user,
      // userManager,
      modalContext: { show },
    } = this.props;
    this.options = [
      {
        title: 'Preferences ',
        icon: {
          name: 'user',
        },
        onClick: () =>
          show(ConnectedUserPreferencesForm, {
            title: t('User Preferences'),
          }),
      },
    ];

    this.serveroptions = [
      {
        title: t('Local'),
        icon: {
          name: 'bars',
        },
        onClick: () =>
          show(userService.server_config(true, 'local'), {
            title: t('Local PACS'),
          }),
      },
      {
        title: t('Cloud'),
        icon: {
          name: 'bars',
        },
        onClick: () =>
          show(userService.server_config(true, 'cloud'), {
            title: t('Cloud PACS'),
          }),
      },
    ];

    // if (localStorage.getItem('user')) {
    //   this.options.push({
    //     title: t('Logout'),
    //     icon: { name: 'power-off' },
    //     onClick: () => userService.logout(this.props.history),
    //   });
    // }
  }

  // ANTD -- Hamburger, Drawer, Menu
  render() {
    const { t, home, location, children, login } = this.props;
    const { appConfig = {} } = this.context;
    const showStudyList =
      appConfig.showStudyList !== undefined ? appConfig.showStudyList : true;
    const dda = home ? (
      <div className="header-logo-text dda">
        {/* {'Detection | Diagnostics | Analytics'} */}
      </div>
    ) : (
      ''
    );

    return (
      <>
        <div className={`entry-header ${home ? 'header-big' : ''}`}>
          <div className="header-left-box">
            {location && location.studyLink && (
              <Link
                to={location.studyLink}
                className="header-btn header-viewerLink"
              >
                {t('Back to Viewer')}
              </Link>
            )}
            {children}
            {showStudyList && !home && !login && (
              <Link
                className="header-btn header-studyListLinkSection"
                to={{
                  pathname: '/',
                  state: { studyLink: location.pathname },
                }}
              >
                {t('Work list')}
              </Link>
            )}
            {dda}
          </div>
          {!login && (
            <div className="header-menu">
              <span className="research-use"></span>
              {home && (
                <Dropdown
                  title={t('Select PACS')}
                  list={this.serveroptions}
                  align="right"
                />
              )}
              <Dropdown
                title={t('Options')}
                list={this.options}
                align="right"
              />
            </div>
          )}
        </div>
      </>
    );
  }
}

export default withTranslation(['Header', 'AboutModal'])(
  withRouter(withModal(Header))
);
