import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { ImUpload3 } from 'react-icons/im';
import { BiReset, BiFilterAlt } from 'react-icons/bi';
class PageToolbar extends PureComponent {
  static propTypes = {
    onImport: PropTypes.func,
    onResetFilter: PropTypes.func,
    onTriageFilter: PropTypes.func,
  };

  onImport = event => {
    if (this.props.onImport) {
      this.props.onImport(event);
    }
  };
  onResetFilter = async event => {
    if (this.props.onResetFilter) {
      await this.props.onResetFilter(event);
    }
  };
  onTriageFilter = async event => {
    if (this.props.onTriageFilter) {
      await this.props.onTriageFilter(event);
    }
  };

  getImportTool() {
    if (this.props.onImport) {
      return (
        <span className="addNewStudy btn-file">
          <label htmlFor="btnImport" onClick={this.onImport}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '0.5rem',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <ImUpload3 style={{ fontSize: '1.2rem' }} />
              Upload
            </div>
          </label>
        </span>
      );
    }
  }
  getResetTool() {
    if (this.props.onResetFilter) {
      return (
        <span className="addNewStudy">
          <label htmlFor="btnImport" onClick={this.onResetFilter}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '0.5rem',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <BiReset style={{ fontSize: '1.2rem' }} />
              Reset Filter
            </div>
          </label>
        </span>
      );
    }
  }

  getTriageTool() {
    if (this.props.onTriageFilter) {
      return (
        <span className="addNewStudy">
          <label htmlFor="btnImport" onClick={this.onTriageFilter}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '0.5rem',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <BiFilterAlt style={{ fontSize: '1.2rem' }} />
              Chest Triage FIlter
            </div>
          </label>
        </span>
      );
    }
  }

  render() {
    return (
      <div className="studylistToolbar">
        {this.getTriageTool()}
        {this.getResetTool()}
        {this.getImportTool()}
      </div>
    );
  }
}

export { PageToolbar };
