import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';
import ConnectedHeader from '../connectedComponents/ConnectedHeader.js';
import UserManagerContext from '../context/UserManagerContext';
import WhiteLabellingContext from '../context/WhiteLabellingContext';
import { TextInput } from '@ohif/ui';
import './login.css';
import PropTypes from 'prop-types';
// import jwt_decode from 'jwt-decode';
import { userService } from '../utils/isauth';

class Login extends Component {
  static propTypes = {
    server: PropTypes.object,
    user: PropTypes.any,
    history: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    if (localStorage.getItem('user')) {
      toast.info("You can't login if you are logged in!", {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
      });
      props.history.push('/');
    }

    this.state = {
      username: null,
      password: null,
      validateform: false,
      isLoading: false,
      error: null,
      submitted: false,
    };
    this.handlechange = this.handlechange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleValidation = this.handleValidation.bind(this);
  }
  handlechange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value }, () => {
      this.handleValidation();
    });
  }

  handleValidation() {
    try {
      this.setState({
        validateform:
          this.state.username.length > 0 && this.state.password.length > 0,
      });
    } catch (e) {
      this.setState({
        validateform: false,
      });
    }
  }

  handleSubmit(e) {
    event.preventDefault();
    if (!this.state.submitted) {
      this.setState({
        submitted: true,
      });
      if (
        !userService.login(
          this.state.username,
          this.state.password,
          this.props.history
        )
      ) {
        this.setState({
          submitted: false,
        });
      }
    }
  }

  render() {
    const { username, password, submitted } = this.state;

    const result = (
      <form onSubmit={this.handleSubmit}>
        <div className="login">
          <div className="title">
            <h4 className="title">Login</h4>
          </div>
          <div className="body">
            <div className="file-info-container">
              <div className="col">
                <div>
                  <TextInput
                    type="text"
                    name="username"
                    value={username}
                    label={'Username'}
                    autoFocus
                    autocomplete={false}
                    onChange={this.handlechange}
                  />
                  {submitted && !username && (
                    <div className="help-block">Username is required</div>
                  )}
                </div>
                <div>
                  <TextInput
                    type="password"
                    value={password}
                    label={'Password'}
                    name="password"
                    autocomplete={false}
                    onChange={this.handlechange}
                  />
                  {submitted && !password && (
                    <div className="help-block">Password is required</div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="footer">
            <input
              type="submit"
              className="btn btn-primary login-btn"
              disabled={!this.state.validateform}
              value="Sign-in"
            />
          </div>
        </div>
      </form>
    );
    return (
      <>
        <WhiteLabellingContext.Consumer>
          {whiteLabelling => (
            <UserManagerContext.Consumer>
              {userManager => (
                <ConnectedHeader
                  home={false}
                  userManager={userManager}
                  login={true}
                >
                  {whiteLabelling.logoComponent}
                </ConnectedHeader>
              )}
            </UserManagerContext.Consumer>
          )}
        </WhiteLabellingContext.Consumer>
        {result}
      </>
    );
  }
}

export default withTranslation('Common')(Login);
