import './StudyListLoadingText.styl';
import React from 'react';
import { Icon } from './../../elements/Icon';
// TODO: useTranslation
import { withTranslation } from '../../utils/LanguageProvider';

function StudyListLoadingText({ t: translate }) {
  return (
    <div className="loading-text">
      {/* {translate('Loading')}... <Icon name="circle-notch" animation="pulse" /> */}
      <div className="cssload-loader">
        <div className="cssload-inner cssload-one"></div>
        <div className="cssload-inner cssload-two"></div>
        <div className="cssload-inner cssload-three"></div>
      </div>
    </div>
  );
}

const connectedComponent = withTranslation('StudyListLoadingText')(
  StudyListLoadingText
);

export { connectedComponent as StudyListLoadingText };
